// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
	font-family: $font-button1;
	font-weight: 500;
	font-size: 1.0em;
	&.btn-primary {
		background-color: $brandcolor1; 
		border-color: $brandcolor1;
		&:hover,
		&:focus {
			background-color: white;
			border-color: white;
			color: $brandcolor1;
			// box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
		}
	}
	&.btn-outline-primary {
		border-color: white;
		color: white;
		&:hover, &:focus {
			background-color: white;
			border-color: white;
			color: $brandcolor1;
			// box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
		}
	}
	&.btn-link {
		color: $brandcolor2;
		&:hover,
		&:focus { 
			color: lighten($brandcolor1, 10%);
		}
	}
	&.btn-lg {
		font-size: 1.25em;
	}
	&.btn-sm {
		font-size: 0.8em;
	}
}

.page-item {
	.page-link {
		background: $brandcolor1;
		color: white;
	}
	&.disabled {
		.page-link {
			background: $gray-400; 
			color: white;
		}
	}
}