// -----------------------------------------------------------------------------
// This file contains all styles related to the navigation of the site/application.
// -----------------------------------------------------------------------------
nav#utility {
	display: none;
	background: $brandcolor1;
	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: center;
		.btn:not(:last-child) {
			margin-right: 0.5em;
		}
	}
}

nav#slideDown {
	.navbar-brand {
		img {
			width: 3em;
		}
	}
	a#mainMenu {
		position: fixed;
		right: 1em;
		z-index: 9999999;
	}
}

.navbar {
	.navbar-brand {
		img {
			width: 10em;
		}
	}
}

.menu-container {
	padding: 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: white;
	border-bottom: 5px solid $brandcolor1;
	.is-sticky & { height: 5em; }
	.sidr-animating & {
		background: none;
	}
	.sidr-open & {
		background: none;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 1em;
	}
	.navbar-logo {
		background: url('/assets/images/branding/sunrise-tours-logo.svg') no-repeat left/contain;
		width: 9em;
		height: 4em;
		@include media-breakpoint-up(lg) {
			background: url('/assets/images/branding/sunrise-tours-logo.svg') no-repeat left/contain;
			width: 9em;
			height: 4em;
		}
		.is-sticky & {
			background: url('/assets/images/branding/sunrise-tours-logo.svg') no-repeat left/contain;
		}
		span {
			opacity: 0;
		}
	}
	.buttons {
		display: block;
		@include media-breakpoint-up(lg) { display: none; }
		.is-sticky & {
			display: block;
		}
		.btn-outline-primary {
			display: none;
			color: $brandcolor1;
			border-color: $brandcolor1;
			&:hover,
			&:focus {
				background-color: $brandcolor1;
				color: white;
				border-color: $brandcolor1;
			}
			@include media-breakpoint-up(md){ display: inline-block; }
		}
	}
	.menu {
		display: none;
		@include media-breakpoint-up(lg) {
			display: block;
			.is-sticky & {
				display: none;
			}
		}
		font-family: $font-nav1;
		font-weight: 500;
		font-size: 0.8125rem;
		@media (min-width: 1472.9px) {
			font-size: 1.125rem;
		}
		@media (max-width: 1472.9px) {
			font-size: 0.9375rem;
		}
		@media (max-width: 1264.9px) {
			font-size: 0.875rem;
		}
		@media (max-width: 1106.9px) {
			font-size: 0.75rem;
		}
		.menu-mobile {
			display: none;
		}
		>ul {
			margin: 0 auto;
			width: 100%;
			list-style: none;
			padding: 0;
			position: relative;
			/* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
			box-sizing: border-box;
			&:before {
				content: "";
				display: table;
			}
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			>li {
				float: left;
				padding: 0;
				margin: 0;
				a {
					color: $gray-900;
					text-decoration: none;
					display: block;
					padding: 1.5em 0.75em;
					@include media-breakpoint-up(xl) {
						padding: 1.5em 1em;
					}
				}
				&:hover {
					background: $brandcolor1;
					a {
						color: white;
					}
				}
				>ul {
					display: none;
					width: 100%;
					background: #f0f0f0;
					padding: 20px;
					position: absolute;
					z-index: 99;
					left: 0;
					margin: 0;
					list-style: none;
					box-sizing: border-box;
					&:before {
						content: "";
						display: table;
					}
					&:after {
						content: "";
						display: table;
						clear: both;
					}
					>li {
						margin: 0;
						padding-bottom: 0;
						list-style: none;
						width: 25%;
						background: none;
						float: left;
						a {
							color: $gray-900 !important;
							padding: .2em 0;
							width: 95%;
							display: block;
							border-bottom: 1px solid #ccc;
						}
						>ul {
							display: block;
							padding: 0;
							margin: 10px 0 0;
							list-style: none;
							box-sizing: border-box;
							&:before {
								content: "";
								display: table;
							}
							&:after {
								content: "";
								display: table;
								clear: both;
							}
							>li {
								float: left;
								width: 100%;
								padding: 10px 0;
								margin: 0;
								font-size: .8em;
								a {
									border: 0;
								}
							}
						}
					}
					&.normal-sub {
						width: 300px;
						left: auto;
						padding: 10px 20px;
						>li {
							width: 100%;
							a {
								border: 0;
								padding: 1em 0;
							}
						}
					}
				}
			}
		}
	}
	#mainMenu {
		height: 40px;
		width: 40px;
		margin-top: 10px;
		z-index: 9999999;
		position: relative;
		display: block;
		@include media-breakpoint-up(lg) {
			display: none;
			.is-sticky & {
				display: block;
			}
		}
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .2s ease-in-out;
		-moz-transition: .2s ease-in-out;
		-o-transition: .2s ease-in-out;
		transition: .2s ease-in-out;
		cursor: pointer;
		span {
			display: block;
			position: absolute;
			height: 5px;
			width: 100%;
			background: $brandcolor1;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .1s ease-in-out;
			-moz-transition: .1s ease-in-out;
			-o-transition: .1s ease-in-out;
			transition: .1s ease-in-out;
			&:nth-child(1) {
				top: 0px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
			&:nth-child(2) {
				top: 12px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
			&:nth-child(3) {
				top: 24px;
				-webkit-transform-origin: left center;
				-moz-transform-origin: left center;
				-o-transform-origin: left center;
				transform-origin: left center;
			}
		}
		.sidr-open & {
			span {
				background: white;
				&:nth-child(1) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					top: 0;
					left: 8px;
				}
				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}
				&:nth-child(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
					top: 29px;
					left: 8px;
				}
			}
		}
	}
}

.sidr {
	background: $brandcolor1;
	width: 100vw;
	@include media-breakpoint-up(md) {
		width: auto;
	}
	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 629.9px) {
			flex-direction: column;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
		.btn {
			margin-bottom: 10px;
			padding: .4rem;
			font-size: 1rem;
		}
		.btn + .btn {
			margin-left: 6px;
		}
	}
	.container {
		margin-top: 1.25em;
		.col-md-7 {
			display: none;
			.card {
				.card-img-top {
					height: 12em;
					position: relative;
					.card-img-overlay {
						background: rgba(black, 0.5);
						color: white;
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}
				.list-inline {
					padding: 1em 0;
					background: $gray-900;
					color: white;
					display: flex;
					justify-content: space-around;
				}
				.card-body{
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.card-footer{
					background-color: white;
					border-top: none;
				}
			}
			@include media-breakpoint-up(md) {
				display: flex;
			}
		}
		.side-navigation-close-container {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			#mainMenu {
				height: 40px;
				width: 40px;
				margin: 0.4rem 1.25rem;
				z-index: 9999999;
				position: relative;
				display: block;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: .2s ease-in-out;
				-moz-transition: .2s ease-in-out;
				-o-transition: .2s ease-in-out;
				transition: .2s ease-in-out;
				cursor: pointer;
				@include media-breakpoint-down(md) {
					display: none;
				}
				span {
					display: block;
					position: absolute;
					height: 5px;
					width: 100%;
					background: $brandcolor1;
					opacity: 1;
					left: 0;
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
					-webkit-transition: .1s ease-in-out;
					-moz-transition: .1s ease-in-out;
					-o-transition: .1s ease-in-out;
					transition: .1s ease-in-out;
					&:nth-child(1) {
						top: 0px;
						-webkit-transform-origin: left center;
						-moz-transform-origin: left center;
						-o-transform-origin: left center;
						transform-origin: left center;
					}
					&:nth-child(2) {
						top: 24px;
						-webkit-transform-origin: left center;
						-moz-transform-origin: left center;
						-o-transform-origin: left center;
						transform-origin: left center;
					}
				}
				.sidr-open & {
					span {
						background: white;
						&:nth-child(1) {
							-webkit-transform: rotate(45deg);
							-moz-transform: rotate(45deg);
							-o-transform: rotate(45deg);
							transform: rotate(45deg);
							top: 0;
							left: 8px;
						}
						&:nth-child(2) {
							-webkit-transform: rotate(-45deg);
							-moz-transform: rotate(-45deg);
							-o-transform: rotate(-45deg);
							transform: rotate(-45deg);
							top: 29px;
							left: 8px;
						}
					}
				}
			}
		}
		.side-navigation-container {
			.navbar-nav {
				text-align: center;
				@include media-breakpoint-down(lg) {
					padding-top: 5.0rem;
				}
				@include media-breakpoint-up(md) {
					text-align: right;
				}
				.nav-item {
					.nav-link {
						font-size: 1.25rem;
						padding: 0.4rem 1.25rem;
						color: white;
					}
				}
			}
		}
	}
}

.sidr.right {
	right: -100vw;
}

.pagenav {
	margin: 4em 0;
	.pagination {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p { margin-bottom: 0; }
	}
}

.btn-nav-accordion-button {
	margin: 0 0 0.625rem 0 !important;
	padding: 0.625rem;
	border-radius: 0;
	border: 1px solid #CCC;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fa {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $brandcolor2;
	}
	.category-nav-text {
		display: inline-block;
		margin: 0;
		font-family: $font-nav1;
		color: $brandcolor2;
		@media (max-width: 767.9px) {
			font-size: 1.5rem;
		}
		line-height: 1;
	}
}
