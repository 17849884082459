.testimonials {
	padding: 5.0em;
	.components-wrapper {
		max-width: 1000px;
	}
	.page-components .row {
		padding: 2.0em 0;
		border-bottom: 2px solid $brandcolor1;
	}
	.component-divider {
		margin: 0.25em 0;
	}
}