// ---------------------------------------------
// Basic typography style for copy text
// ---------------------------------------------
html, body {
	font-family: $font-body1;
	font-weight: 500;
	font-size: 14px;
	@include media-breakpoint-up(sm){ font-size: 16px; }
	@include media-breakpoint-up(md){ font-size: 18px; }
	@include media-breakpoint-up(lg){ font-size: 20px; }
	@include media-breakpoint-up(xl){ font-size: 20px; }
}

h1,
h2 {
	font-family: $font-heading1;
	font-weight: 700;
}

h3,
h4 {
	font-family: $font-heading1;
	font-weight: 500;
}

h4 {
	font-size: 0.8em;
	text-transform: uppercase;
	//letter-spacing: 0.15em;
}

h5,
h6 {
	font-family: $font-heading2;
	font-weight: 700;
	//style: italic;
	//letter-spacing: 0.1em;
}

h5 {
	font-size: 0.8em;
}

h6 {
	font-size: 0.6em;
}

body,
p,
a {
}

a {
	color: $brandcolor2;
	&:hover,
	&:focus { color: lighten($brandcolor1, 10%); }
}

.sidebar-heading {
	font-family: $font-heading1;
	text-transform: uppercase
}

.list-group-flush {
	margin: 0 0 2.5rem 0;
	padding: 0;
	.list-group-item {
		margin: 0;
		padding: 0.625rem 0;
		font-family: $font-nav1;
		font-size: 0.875rem;
		line-height: 1;
		&:not(:last-child) {
			border-bottom: 1px solid #CCC;
		}
		&:last-child {
			border-bottom: none;
		}
		a {
			display: block;
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
			&:active {
				text-decoration: none;
			}
			&.active {
				//font-weight: 700;
				color: black;
			}
		}
		@media (max-width: 767.9px) {
			padding: 1.0rem 0;
			font-size: 1.5rem;
		}
	}
}

.tooltip {
	.tooltip-inner {
		font-size: 0.625rem;
		@media (max-width: 767.9px) {
			font-size: 0.8125rem;
		}
	}
}
