#modal-request {
	font-size: .9rem;

	.form-control {
		font-size: .9rem;
	}

	.modal-dialog {
		max-width: 800px;
		margin: 1.75rem auto;
	}
}

.modal {
	z-index: 99999996;
}

.modal-backdrop.show {
	z-index: 99999995;
}

.ctct-inline-form {
	font-family: $font-body1;
	.ctct-form-container {
		.ctct-form-defaults {
			.ctct-form-custom {
				.ctct-form-header {
					font-family: $font-body2;
					font-weight: 700;
				}
				.ctct-form-label {
					font-family: $font-heading1;
					font-weight: 700;
				}
				.ctct-form-required {
					&:before {
						color: $brandcolor1-dark;
					}
				}
				.ctct-form-button {
					font-family: $font-button1;
					font-weight: 500;
					font-size: 1.5rem;
					background-color: $brandcolor1; 
					border-color: $brandcolor1;
					&:hover,
					&:focus {
						background-color: white;
						border-color: $brandcolor1;
						color: $brandcolor1;
					}
				}
			}
		}
	}
}
