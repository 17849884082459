.team {
	padding: 4em 0;
	.card {
		border-radius: 0;
		font-size: 0.8em;
	}
	.card-title {
		font-size: 1.0em;
		margin: 0 0 0.2em 0;
	}
	.team-card-divider {
		margin: 0 0 0.2em 0;
		padding: 0;
	}
	.team-card-link {
		font-family: $font-nav1;
		font-weight: 700;
		font-size: 0.75em;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}
}

.back-link-container {
	padding-bottom: 0.25em;
	border-bottom: 1px solid $brandcolor1;
	.back-link {
		margin: 0;
		padding: 0;
		font-size: 0.8em;
	}
}