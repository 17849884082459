// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Old Colors
// $green: #6d9201;
// $orange: #F93C09;

// Basic Colors
$white: #FFFFFF;
$black: #000000;

// Main Brand Color - Yellow Sun Colors
$brandcolor1-light: #FFDAA0;
$brandcolor1-light2: #FFEDA1;
$brandcolor1: #FFA311;
$brandcolor1-dark: #CC7E00;

// 2nd Brand Color - Sky Blue
$brandcolor2-light: #B3C9D7;
$brandcolor2: #40789B;
$brandcolor2-dark: #204D69;

// Tertiary Brand Colors
$brandcolor3: #B66113;
$brandcolor4: #FFDAA0;
$brandcolor5: #FFEDA1;
$brandcolor6: #00AEEF;
$brandcolor7: #ED1D24;
$brandcolor8: #41AD49;
$brandcolor9: #640ECC;

// Grays
$gray-light1: #f2f2f2;
$gray-light2: #E6E6E6;
$gray-light3: #CCCCCC;

$gray-mid1: #B3B3B3;
$gray-mid2: #999999;
$gray-mid3: #808080;

$gray-dark1: #666666;
$gray-dark2: #4D4D4D;
$gray-dark3: #333333;

//Main Brand Fonts
$font-body1: essonnes-text, Garamond, "Times New Roman", Georgia, serif;
$font-body2: essonnes-display, Garamond, "Times New Roman", Georgia, serif;

$font-heading1: futura-pt, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-heading2: futura-pt-condensed, Helvetica, Calibri, 'Segoe UI', sans-serif;

$font-nav1: futura-pt, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-nav2: futura-pt-condensed, Helvetica, Calibri, 'Segoe UI', sans-serif;

$font-button1: futura-pt, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-button2: futura-pt-condensed, Helvetica, Calibri, 'Segoe UI', sans-serif;

//Icon Fonts
$font-icons1: 'FontAwesome';
$font-icons2: 'CountryTravelDiscoveries';

/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/