#popular-tours,
#seasons {
  .col-md-3,
  .col-lg-3 {
    .card {
      @include media-breakpoint-up(lg){
        margin-right: 0.4em;
      }
    }
  }
  .card {
    border-radius: 0;
    border: none;
    position: relative;
    height: 15em;
    z-index: 0;
    margin: 0.3em 0;
    @include media-breakpoint-up(lg) {
      height: 20em;
    }
    .card-body {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      align-items: center;
      color: white;
      .card-title {
        transform: translateY(1em);
        transition: transform 0.2s ease-in;
      }
      .btn-primary {
        max-width: 80%;
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }
    }
    .card-overlay {
      position: absolute;
      background: rgba($black, 0.6);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover,
    &:focus {
      .card-title {
        transform: translateY(0);
      }
      .btn-primary {
        opacity: 1;
      }
      .card-overlay {
        opacity: 1;
      }
    }
  }
}
