.about-us {
  .page-content:nth-child(2){ display: none; }
}

#FAQlist {
  margin-top: 5.0rem;
  margin-bottom: 7.5rem;
  .card {
    .card-header {
      .btn-link {
        margin: 0;
        padding: 0;
        font-size: 1.25rem;
      }
    }
  }
}