#testimonials {
	background: $brandcolor1;
	padding: 0;
	margin: 0;
	border-bottom: 10px solid white;
	.slick-list {
		.slick-track {
			display: flex !important;
			.slick-slide {
				height: inherit !important;
				.row {
					height: 100%;
					.col-md-6 {
						background: $brandcolor1;
						color: white;
						padding: 5em 2em 5.0em 2em;
						text-align: center;
						height: 100%;
						hr { 
							border-color: white;
						}
						&:nth-child(2){
							display: none;
							@include media-breakpoint-up(md) { 
								display: block;
							}
						}
					}
				}
			}
		}
	}
}