.blog-post {
	display: block;
	margin-bottom: 3.0em;
	.img-fluid {
		//max-height: 350px;
	}
	figure {
		display: inline;
	}
}

.BlogTagList {
	margin-bottom: 1.25rem;
	ul {
		li {
			font-family: $font-nav1;
			font-size: 0.875rem;
			a {			
			}
		}
	}
}