//Text Color Options in Redactor
.color-brandcolor1 {
	color: $brandcolor1;
}

.color-brandcolor2 {
	color: $brandcolor2;
}

.color-brandcolor3 {
	color: $brandcolor3;
}

.color-brandcolor4 {
	color: $brandcolor4;
}

.color-brandcolor5 {
	color: $brandcolor5;
}

.color-brandcolor6 {
	color: $brandcolor6;
}

.color-red {
	color: #ff1212;
}

.color-green {
	color: #0b9917;
}

.color-gray-mid2 {
	color: $gray-mid2;
}

//Text Style Options in Redactor
.font-heading1 {
	font-family: $font-heading1;
}
.font-heading2 {
	font-family: $font-heading2;
}
.font-body1 {
	font-family: $font-body1;
}
.font-body2 {
	font-family: $font-body2;
}