.destination-detail {
  .btn-book-now-secondary {
    left: 0;
    right: 0;
    z-index: 100;
    opacity: 1.0;
    transition: all 500ms ease-in-out;
    &.off-screen {
      bottom: -200px;
      opacity: 0.0;
    }
    &.position-fixed {
      bottom: 0;
    }
  }
}
#tour-categories,
#destinations {
  .col-md-4, .col-md-3 {
    @include media-breakpoint-up(md){
      &:not(:last-child){
        .card { margin-right: 0.5em; }
      }
    }

    .card {
      border-radius: 0;
      border: none;
      position: relative;
      height: 15em;
      z-index: 0;
      margin: 0.3em 0;

      @include media-breakpoint-up(lg) {
        height: 20em;
      }

      .card-body {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        align-items: center;
        color: white;

        .card-title {
          transform: translateY(1em);
          transition: transform 0.2s ease-in;
        }

        .btn-primary {
          max-width: 80%;
          opacity: 0;
          transition: opacity 0.2s ease-in;
        }
      }

      .card-overlay {
        position: absolute;
        background: rgba($black, 0.6);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover,
      &:focus {
        .card-title {
          transform: translateY(0);
        }

        .btn-primary {
          opacity: 1;
        }

        .card-overlay {
          opacity: 1;
        }
      }
    }
  }
}
