.slick-slider {
	overflow: hidden;
	.slider-button {
		position: absolute;
		color: rgba(white, 0.33);
		z-index: 200;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		top: 0;
		background: rgba($brandcolor1, 0);
		backdrop-filter: none;
		font-size: 2rem;
		padding: 0 0.25rem 0 0.5rem;
		-webkit-transition: transform 0.3s ease-in-out;
		-moz-transition: transform 0.3s ease-in-out;
		-ms-transition: transform 0.3s ease-in-out;
		-o-transition: transform 0.3s ease-in-out;
		transition: transform 0.3s ease-in-out;
		&.right { right: 0; }
		&:hover,
		&:focus { 
			background: rgba($brandcolor1, 0.5);
			backdrop-filter: blur(6px);
			color: rgba(white, 0.9);
		}
	}
}